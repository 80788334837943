import { graphql } from 'gatsby';
import React from 'react';

import Content from 'components/shared/content';
import MainLayout from 'layouts/main';
import { getReactContentWithLazyBlocks } from 'utils/get-react-content-with-lazy-blocks';

const LazyBlocks = ({
  data: {
    wpPage: { content, seo },
  },
  pageContext,
}) => {
  const { reactedContent } = getReactContentWithLazyBlocks(content);
  return (
    <MainLayout seo={seo} pageContext={pageContext}>
      <Content content={reactedContent} withoutSubscribeBlock widthFull />
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      content
      title
      url: uri
      ...wpPageSeo
    }
  }
`;

export default LazyBlocks;
